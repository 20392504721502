$(function () {
  $('.voicemail_card').on('click', function (e) {
    if(e.target !== this || e.offsetX > parseInt($(this).css('borderLeftWidth'))){
      return;
    }
    $(this).toggleClass('active');

    const idsInput = $('#voicemails_ids');
    if (!idsInput.length) return;

    const ids = idsInput.val().length ? idsInput.val().split(',') : []
    if ($(this).hasClass('active')) {
      ids.push($(this).data('id'));
    } else {
      ids.splice(ids.indexOf($(this).data('id').toString()), 1);
    }
    idsInput.val(ids.join(','));
    updateSelectedCount();
    updateButtons();
  });

  $('#select_all').on('click', function () {
    $('.voicemail_card').addClass('active');
    $('#select_all').hide();
    $('#deselect_all').show();

    const idsInput = $('#voicemails_ids');
    if (!idsInput.length) return;

    const ids = $('.voicemail_card').map(function () {
      return $(this).data('id');
    }).get();
    idsInput.val(ids.join(','));
    updateSelectedCount();
    $('#delete_selected').show();
  });

  $('#deselect_all').on('click', function () {
    $('.voicemail_card').removeClass('active');
    $('#deselect_all').hide();
    $('#select_all').show();
    $('#delete_selected').hide();

    const idsInput = $('#voicemails_ids');
    if (!idsInput.length) return;
    idsInput.val('');
    updateSelectedCount();
  });
})

function updateSelectedCount() {
  const activeCount = $('.voicemail_card.active').length;
  if (activeCount) {
    $('#selected_count_wrapper').css('visibility', 'visible');
    $('#selected_count').text(activeCount);
  } else {
    $('#selected_count_wrapper').css('visibility', 'hidden');
  }
}

function updateButtons() {
  const activeCount = $('.voicemail_card.active').length;
  const totalCount = $('.voicemail_card').length;
  if (activeCount === totalCount) {
    $('#select_all').hide();
  } else {
    $('#select_all').show();
  }

  if (activeCount) {
    $('#deselect_all').show();
    $('#delete_selected').show();
  } else {
    $('#deselect_all').hide();
    $('#delete_selected').hide();
  }
}
